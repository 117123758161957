



























import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useInvoiceAP } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DataInvoiceAp } from "@/models/interface/invoiceAp.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectInvoiceSuppNo extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<DataInvoiceAp>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    const params = new RequestQueryParams(this.buildQuery());
    this.fetchOptions(params);
  }

  fetchOptions(params: RequestQueryParams): void {
    const { findAll, toSupplierNoOptions } = useInvoiceAP();
    this.loading = true;
    findAll(params)
      .then(({ data }) => {
        this.options = toSupplierNoOptions(data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const builder = new SearchBuilder();
    const { filterBy } = useInvoiceAP();
    const params = new RequestQueryParams();
    const query: string[] = [this.buildQuery()];

    if (val) {
      query.push(filterBy({ invoiceSupplierNumber: val }));
    }

    params.search = query.join(builder.AND);

    this.fetchOptions(params);
  }

  findOption(value: string): Option<DataInvoiceAp> | undefined {
    return this.options.find(e => value === e.value);
  }

  /**
   * @description default query
   * invoice_supplier_no IS NOT NULL
   */
  buildQuery(): string {
    const builder = new SearchBuilder();

    return builder
      .push(["invoiceSupplierNo", "null"], {
        not: true,
      })
      .build();
  }
}
