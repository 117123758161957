


































































































import { useRemoveRows } from "@/hooks";
import { buildAdditionalCostState } from "@/store/account-payable/ap-invoice/resource";
import { ApInvoiceFormAdditionalCostState } from "@/store/account-payable/ap-invoice/types";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Column } from "ant-design-vue/types/table/column";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "AdditionalCostModal",
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    costs: {
      type: Array as () => ApInvoiceFormAdditionalCostState[],
      required: false,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("lbl_amount"),
          key: "amount",
          scopedSlots: { customRender: "amount" },
        },
        {
          title: this.$t("lbl_notes"),
          key: "notes",
          scopedSlots: { customRender: "notes" },
        },
      ] as Column[],
      dataSource: [] as ApInvoiceFormAdditionalCostState[],
      selectedRowKeys: [] as string[],
    };
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue: boolean) {
        if (!newValue) {
          this.dataSource = [];
          return;
        }

        this.dataSource = [...this.costs];
      },
    },
  },
  methods: {
    reverseFormatNumber,
    formatterNumber,
    closeModal() {
      this.$emit("close");
      this.$emit("input", false);
    },
    onSelectRow(keys: string[]) {
      this.selectedRowKeys = keys;
    },
    handleAdd(): void {
      const cost = buildAdditionalCostState();
      this.dataSource.push(cost);
    },
    handleSave(): void {
      const payload: ApInvoiceFormAdditionalCostState[] = [...this.dataSource];
      this.$emit("save", { payload });
      this.$emit("input", false);
    },
    handleDelete(): void {
      const { newSource } = useRemoveRows(
        this.dataSource,
        this.selectedRowKeys
      );
      this.dataSource = [...newSource];
    },
  },
});
