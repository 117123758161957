var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.value,
        width: 900,
        title: _vm.$t("common.manage-text", {
          text: _vm.$t("lbl_additional_cost")
        }),
        "destroy-on-close": ""
      },
      on: { cancel: _vm.closeModal },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { "data-testid": "btn-close" },
                      on: { click: _vm.closeModal }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
                  ),
                  !_vm.readOnly
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary", "data-testid": "btn-save" },
                          on: { click: _vm.handleSave }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-row",
        {
          attrs: {
            "data-testid": "additional-cost-container",
            type: "flex",
            gutter: [16, 16]
          }
        },
        [
          !_vm.readOnly
            ? _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", "data-testid": "btn-add" },
                          on: { click: _vm.handleAdd }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_add")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "danger",
                            "data-testid": "btn-delete"
                          },
                          on: { click: _vm.handleDelete }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_delete")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  size: "small",
                  columns: _vm.columns,
                  "data-source": _vm.dataSource,
                  "row-selection": _vm.readOnly
                    ? undefined
                    : {
                        selectedRowKeys: _vm.selectedRowKeys,
                        onChange: _vm.onSelectRow
                      },
                  "row-class-name": function(_record, index) {
                    return index % 2 ? "bg-white" : "bg-gray-light"
                  },
                  pagination: {
                    showTotal: function(total) {
                      return _vm.$t("lbl_total_items", {
                        total: total
                      })
                    }
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "amount",
                    fn: function(text, record) {
                      return [
                        _vm.readOnly
                          ? _c("span", [
                              _vm._v(_vm._s(_vm._f("currency")(record.amount)))
                            ])
                          : _c("a-input-number", {
                              staticClass: "w-100",
                              attrs: {
                                placeholder: _vm.$t("common.insert-text", {
                                  text: _vm.$t("lbl_amount")
                                }),
                                formatter: _vm.formatterNumber,
                                parser: _vm.reverseFormatNumber,
                                min: 0,
                                precision: _vm.storeBaseDecimalPlace,
                                "allow-clear": "",
                                "data-testid": "input-amount"
                              },
                              model: {
                                value: record.amount,
                                callback: function($$v) {
                                  _vm.$set(record, "amount", $$v)
                                },
                                expression: "record.amount"
                              }
                            })
                      ]
                    }
                  },
                  {
                    key: "notes",
                    fn: function(text, record) {
                      return [
                        _vm.readOnly
                          ? _c("span", [_vm._v(_vm._s(record.notes || "-"))])
                          : _c("a-input", {
                              attrs: {
                                "allow-clear": "",
                                placeholder: _vm.$t("common.insert-text", {
                                  text: _vm.$t("lbl_notes")
                                }),
                                "data-testid": "input-notes"
                              },
                              model: {
                                value: record.notes,
                                callback: function($$v) {
                                  _vm.$set(record, "notes", $$v)
                                },
                                expression: "record.notes"
                              }
                            })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }